/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import Page from "../layouts/page";

const hero = css`
font-size: 4em;
font-weight: normal;
`

const rapidIxSpan = css`
color: #44718E;
`

const GetConnected = () => {
    return <Page>
        <h1 css={hero}>Connect to <span css={rapidIxSpan}>RapidIX</span></h1>

        <p>RapidIX has an open peering policy, contact us at <a href="mailto:support@rapidix.net">support@rapidix.net</a> for any enquiries regarding exchange membership.</p>
    </Page>
}

export default GetConnected
